@import "../../global.scss";

.inside {
  margin-top: 4.363rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inside-container {
    width: $widthContainer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop {
      height: 32.375rem;
    }

    .title {
      font-size: 3.125rem;
      font-weight: 700;
      letter-spacing: 0.2rem;
      text-align: center;
      color: $blackColor;

      @include mobile {
        font-size: 1.5rem;
        margin-bottom: 1.875rem;
        text-align: left;
        letter-spacing: 0.05rem;
      }
    }

    .cards {
      @include desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-container {
        width: 100%;
        margin: 0 auto; // Mobile
        margin-bottom: 2rem;
        position: relative;

        @include desktop {
          // width: 18.125rem;
          width: 23.3%;
          height: 22.5rem;
          margin: 0;
        }

        img {
          width: 100%;
          object-fit: cover;
          margin-bottom: 1.25rem;
          transition: 0.3s ease;

          &:hover {
            transform: scale(1.025);
          }
        }

        .title-card {
          font-size: 1.375rem;
          line-height: 2rem;
          font-weight: 700;
          color: $blackColor;
          margin-bottom: 1.25rem;
        }

        .desc-card {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.875rem;
          color: $grayColor;
          margin-bottom: 1.25rem;
        }

        .learn-more {
          position: relative;
          bottom: 0;
          font-size: 0.875rem;
          letter-spacing: 0.05rem;
          font-weight: 700;
          text-transform: uppercase;
          color: $darkGrayColor;
          display: flex;
          align-items: center;

          @include desktop {
            position: absolute;
          }

          a {
            color: inherit;
            text-decoration: none;
          }

          &:hover {
            color: rgba(99, 100, 101, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
}
