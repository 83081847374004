@import "../global";

.loading {
    width: 100%;
    height: 80vh;
    margin-top: $heigtHeader;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .loading__container {
        width: 100%;
        height: 100%;
        position: relative;

        @include mobile {
            height: 100vh;
        }

        .handphone {
            width: 17%;
            position: absolute;
            top: 10%;
            left: 40%;
            transform: translate(-40%);
            animation: handphone-animation 1s infinite;

            @include mobile {
                width: 30%;
                top: 20%;
            }
        }

        .kiri {
            width: 6%;
            position: absolute;
            top: 30%;
            left: 25%;
            transform: translate(-50%);
            animation: koin-animation 1.2s infinite;
        }

        .tengah {
            width: 6%;
            position: absolute;
            top: 40%;
            left: 30%;
            transform: translate(-50%);
            animation: koin-animation 1s infinite;
        }

        @keyframes handphone-animation {
            0% {
                transform: translateY(0rem);
            }
            50% {
                transform: translateY(2rem);
            }
            100% {
                transform: translateY(0rem);
            }
        }

        @keyframes koin-animation {
            0% {
                transform: translate(0%,0vh);
                opacity: 0;
            }
            50% {
                transform: translate(20vw,-10vh);
                opacity: 1;
            }
            100% {
                transform: translate(0%,10vh);
                opacity: 0;
            }
        }
    }


}