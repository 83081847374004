@import "../../global";

.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    height: auto;
  }
}

.container-popup {
  width: 76%;
  animation: fadeIn 1s;
  background-color: white;
  border-radius: 1.25rem;
  // overflow: hidden;
  position: relative;
  padding: 3.75rem 2.5rem;
  display: flex;

  @include mobile {
    // position: absolute;
    top: 2vh;
    display: block;
    width: 90vw;
    padding: 2.2rem 1.5rem;
  }

  .close-popup {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.left {
  flex: 1;
  padding: 1rem;
}
.img-popup {
  width: 14.25rem;
  height: 14.25rem;
  border-radius: 100%;
  border: 0.688rem solid rgba(255, 188, 37, 0.3);
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    object-fit: cover;
    border: 0.3rem solid white;
    transition: 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.content-popup {
  flex: 2;
  // position: relative;
  // overflow: hidden;
}

.desc-content-popup p {
  font-size: 1rem;
  line-height: 30px;
  text-align: justify;
  color: #636465;
  padding: 11px;

  @include mobile {
    height: 50vh;
    // background-color: tomato;
    overflow-y: scroll;
  }
}

@keyframes hidden {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(20rem);
  }
}
