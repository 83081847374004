@import "../../global";

.milestone {
  width: 100%;
  // height: 59.375rem; // Buat Cover
  overflow: hidden;
  background-color: $blackColor;
  color: white;
  margin-bottom: 10rem;
  padding: 6.25rem;
  position: relative;
  transition: all 0.3s ease;

  @include mobile {
    padding: 3rem 0.5rem;
  }

  .image__wrapper {
    position: absolute;
    top: -15rem;
    left: -5rem;
    width: 38.438rem;

    @include mobile {
      top: -10rem;
      left: -5rem;
      width: 25rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .visible {
    height: 0;
  }

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), $blackColor);
    display: flex;
    justify-content: center;
    align-items: center;

    .tab {
      background-color: #ffbc25;
      border-radius: 2.5rem;
      border: 0.131rem solid #ffbc25;
      padding: 1.25rem 2rem;
      font-weight: 600;
      transition: all 0.2s ease;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: $blackColor;
      position: absolute;
      bottom: 6.25rem;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 3.125rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-align: center;
    margin-bottom: 3.75rem;

    @include mobile {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }

  .milestone__wrapper {
    // width: 48.125rem;
    width: 53.125rem;
    height: 15.75rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: 100%;
      display: block;
      height: auto;
      margin: 2rem 0;
    }

    .milestone__year {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 0 2.5rem 0 0;
      // width: 12rem;
      width: 14rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -0.32rem;

      @include desktop {
        border-right: 0.5rem solid $yellowColor;
      }

      @include mobile {
        border-left: 0.5rem solid $yellowColor;
        height: auto;
        width: 100%;
        padding: 0 0.5rem;
        justify-content: flex-start;
        // display: block;
      }
    }

    .limit {
      color: $yellowColor;
      font-size: 1.5rem;
      height: 100%;
      border-left: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 1rem;

      @include mobile {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0.7rem;
      }
    }

    .milestone__desc {
      width: 42.75rem;
      font-size: 1.25rem;
      line-height: 2.5rem;
      //   border-left: 0.1rem solid $grayColor;
      padding: 0 0 0 4.875rem;
      transition: all 0.3s ease-in-out;
      display: flex;
      gap: 1rem;

      img {
        width: 6.375rem;
        height: 2rem;
      }

      @include mobile {
        width: 100%;
        padding: 0 1rem;
        height: auto;

        // display: block;
      }

      &:hover {
        color: $yellowColor;
      }
    }
  }
}
