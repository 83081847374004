@import "../../global";

.heroimage {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    // height: 30.125rem;
    object-fit: cover;
  }

  // .heightProducts {
  //   height: 30.125rem;
  // }

  // .heightPay {
  //   height: 25.625rem;
  // }

  // .heightManagement {
  //   height: 16.563rem;
  // }

  // .heightHelp {
  //   height: 23.75rem;
  // }

  .wrapper__title__banner {
    width: 100%;
    position: absolute;
    top: 9.5%;
    left: 0;
    // transform: translateX(-50%);
    padding: 0 6%;
    color: $blackColor;

    // WHITE
    &.white {
      color: #fff;
    }

    @include desktop {
      padding: 0 3rem;
      width: 42%;
      top: 21.2%;
      left: 25%;
      transform: translateX(-50%);
    }

    .title__bannerLeft {
      width: 75%;
      font-weight: 700;
      font-size: 3rem;
      letter-spacing: 0.02em;
      line-height: 3.625rem;

      @include desktop {
        // line-height: 5.625rem;
        // font-size: 4.9rem;
        line-height: 5.1rem;
        font-size: 4.2rem;
      }

      &.full {
        width: 100%;
      }
    }

    .desc__bannerLeft {
      width: 100%;
      line-height: 1.875rem;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.02em;
      margin-top: 1.625rem;
      opacity: 0.7;

      @include desktop {
        width: 75%;
        margin-top: 1.25rem;
        line-height: 1.875rem;
        font-size: 1.125rem;
      }
    }
  }

  .title__banner {
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 5.625rem;
    font-weight: 700;
    color: $blackColor;
    white-space: nowrap;
    font-size: 1.7rem;
    letter-spacing: 0.02em;

    // Yellow
    &.yellow {
      color: $yellowColor;
    }

    @include desktop {
      font-size: 3.125rem;
    }
  }
}
