@import "../../global";

.detailnews {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: $widthContainer;
    padding: 5.125rem 0;

    .container__other {
      margin: 0 auto;
      width: 100%;

      @include desktop {
        width: 67%;
      }

      .detailnews__title {
        font-weight: bold;
        font-size: 3.125rem;
        line-height: 5rem;
        letter-spacing: 0.02em;
        color: $blackColor;
        margin-bottom: 1.5rem;

        span {
          text-transform: uppercase;
        }

        @include mobile {
          font-size: 2.3rem;
          line-height: 3rem;
        }
      }

      .detailnews__datePublish {
        font-weight: bold;
        font-size: 1rem;
        line-height: 2.1rem;
        color: rgba(51, 51, 51, 0.7);
        margin-bottom: 1.25rem;
      }

      .image__wrapper {
        width: 100%;
        overflow: hidden;
        margin-bottom: 5rem;
        border-radius: 1.2rem;

        @include desktop {
          border-radius: 1.25rem;
          height: 35rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 2.1rem;
        color: rgba(51, 51, 51, 0.7);
        margin-bottom: 1.25rem;
      }

      .subtitle {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.125rem;
        letter-spacing: 0.04em;
        color: $blackColor;
        margin-top: 5.625rem;
        margin-bottom: 2.5rem;
      }

      ol {
        li {
          display: list-item;
          list-style-type: decimal;
          list-style-position: outside;
          margin-left: 1.2rem;
          margin-bottom: -1rem;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
          white-space: inherit;
        }
      }

      ul {
        display: block;

        li {
          display: list-item;
          list-style-type: disc;
          list-style-position: outside;
          margin-left: 2.2rem;
          margin-bottom: -1rem;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
          white-space: inherit;
        }
      }

      .table__wrapper {
        width: 100%;
        overflow-x: auto;
      }

      // Table
      table {
        width: 100%;
        overflow: hidden;
        border-radius: 1.25rem;
        box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
        // margin-bottom: 5rem;
        margin-bottom: 3rem;

        thead {
          background-color: $blackColor;
          height: 4.5rem;
          color: $whiteColor;
          font-size: 1.375rem;
          font-weight: 700;

          @include mobile {
            height: 3.5rem;
          }

          tr {
            th {
              padding: 1.25rem;

              // &:nth-child(2) {
              //   width: 15%;
              // }
            }
          }

          @include mobile {
            font-size: 0.875rem;
          }
        }

        tbody tr {
          height: 4.5rem;
          color: $blackColor;
          font-size: 1rem;
          line-height: 1.6rem;
          font-weight: 500;
          letter-spacing: 0.01rem;
          transition: all 0.3s linear;

          // Memperbesar kolom saldo
          // &:nth-child(8) {
          //   td {
          //     &:nth-child(3) {
          //       width: 20%;
          //       // background-color: tomato;
          //     }
          //   }
          // }

          @include mobile {
            font-size: 0.7rem;
            height: 3.5rem;
          }

          td {
            // border-right: 0.188rem solid $whiteColor;
            // padding-left: 1.5rem;
            // padding-right: 1.5rem;
            padding: 0.8rem 1rem;
            border: 1px solid $ligthGrayColor;

            // &:nth-child(3) {
            //   background-color: $ligthGrayColor;
            //   text-align: center;
            // }

            // Jika ada table di dalam table
            table {
              border-radius: 0;
              margin-bottom: 0;
              // margin-left: -1.5rem;
              // width: 100%;
            }

            @include mobile {
              padding-left: 0.5rem;
            }

            p {
              font-size: 1rem;

              @include mobile {
                font-size: 0.7rem;
              }
            }

            ul {
              display: list-item;
              list-style-type: disc;
              list-style-position: outside;
              margin-left: 0.5rem;

              li {
                white-space: pre-wrap;
                line-height: 1.625rem;
                color: $blackColor;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0.01rem;
                display: list-item;

                @include mobile {
                  font-size: 0.7rem;
                }
              }
            }
          }
        }

        tbody tr:nth-child(2n) {
          background-color: $ligthGrayColor;
        }
      }

      .share__icons {
        display: flex;
        align-items: center;
        margin-top: 2.5rem;
        gap: 1.25rem;

        .share {
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.1rem;
          color: rgba(51, 51, 51, 0.7);
        }

        .img__wrapper {
          background: #f2f2f2;
          border-radius: 4px;
          overflow: hidden;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50%;
            height: 50%;
            transition: all 0.3s ease;

            &:hover {
              cursor: pointer;
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
