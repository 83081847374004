@import "../../global";

.investorrelation {
  width: 100%;
  margin-top: $heigtHeader;

  &__contents {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3.875rem;
    margin-bottom: 3.875rem;

    .contents__container {
      width: $widthContainer;

      @include desktop {
        display: flex;
        gap: 1.5rem;
      }

      .left {
        flex: 1.15;
      }

      .left__sidebar {
        // flex: 1;
        padding: 0.5rem;
        background: $blackColor;
        border-radius: 0.75rem;

        @include mobile {
          margin-bottom: 3rem;
        }

        .menus__wrapper {
          // width: 17.125rem;
          // height: 4.625rem;
          border-radius: 0.75rem;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: 0.1s ease;
          padding: 1.5rem 1rem;
          animation: fadeIn 1s;
          margin-bottom: 0.5rem;

          @include mobile {
            width: 100%;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-2rem);
            }
            to {
              opacity: 1;
            }
          }

          &:hover,
          &.active {
            background-color: $yellowColor;
            cursor: pointer;
            color: $blackColor;
            font-weight: bold;
          }

          .menu__wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-right: 1.2rem;
          }

          .icon,
          .arrow {
            width: 1.5rem;
            height: 1.5rem;
          }

          .menu__title {
            font-size: 1rem;
            line-height: 1.5rem;
          }

          .submenu__title {
            font-size: 1rem;
            line-height: 1.5rem;
            padding-left: 2.3rem;
          }
        }
      }

      .right {
        flex: 3.2;
        padding: 1rem;
      }

      .right__sidebar {
        // width: 52.5rem;
        padding: 2.125rem;
        background: $blackColor;
        border-radius: 0.75rem;
        background: #ffffff;
        border: 1px solid rgba(51, 51, 51, 0.2);
        box-shadow: 0px 1px 3px rgba(63, 61, 60, 0.15),
          0px 0px 0px rgba(63, 61, 60, 0.05);
        margin-bottom: 1.5rem;

        @include mobile {
          width: 100%;
        }

        .header__sidebar {
          color: $blackColor;
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   margin-bottom: 2rem;
          cursor: pointer;

          .title__sidebar {
            font-size: 1.375rem;
            font-weight: 600;
            letter-spacing: 0.01em;
          }
        }

        .pdf {
          margin-top: 2rem;
          animation: fadeIn 0.5s;

          p {
            margin-bottom: 2rem;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.8rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            // color: "#333333",

            a {
              color: inherit;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
