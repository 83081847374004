@import "../../global.scss";

.footer {
  width: 100%;

  // .subcribe {
  //   width: 100%;
  //   height: 24.313rem;
  //   background-color: $blackColor;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   .subcribe-wrapper {
  //     width: 40.875rem;
  //     height: 11.5rem;

  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;

  //     @include desktop {
  //       padding: 0;
  //       width: 46.875rem;
  //       height: 11.5rem;
  //     }

  //     h2 {
  //       font-size: 2.4rem;
  //       font-weight: 500;
  //       color: $whiteColor;
  //       text-align: center;

  //       @include desktop {
  //         font-size: 3.125rem;
  //         font-weight: 700;
  //       }
  //     }

  //     .img-wrapper {
  //       display: flex;
  //       justify-content: center;
  //       gap: 2rem;

  //       img {
  //         width: 9.063rem;
  //         height: 3rem;
  //         object-fit: cover;

  //         @include desktop {
  //           width: 13.063rem;
  //           height: 3.875rem;
  //         }
  //       }
  //     }
  //   }
  // }

  .main-footer {
    width: 100%;
    background-color: $veryBlackColor;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    @include desktop {
      height: 16.875rem;
    }

    .main-footer-container {
      margin: 0 auto;
      width: 100%;
      padding: 0 1rem;
      //   background-color: white;
      @include desktop {
        width: $widthContainer;
        padding: 0;
      }

      .footer-navigation {
        @include desktop {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        img {
          width: 10.032rem;
          height: 2.234rem;
          object-fit: cover;
          margin: 1rem 0;
        }

        ul.second-navigation {
          @include mobile {
            display: block;
          }

          li {
            color: $whiteColor;
            font-size: 0.875rem;
            margin: 0 1rem;

            &:hover {
              cursor: pointer;
              color: $yellowColor;
            }

            @include mobile {
              margin: 0;
            }
          }
        }
      }
      // medsos
      .medsos {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        margin: 1rem 0 1.688rem 0;

        @include mobile {
          justify-content: flex-start;
        }

        .wrapper-medsos {
          width: 2.5rem;
          height: 2.5rem;
          background-color: $blackColor;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease;

            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }
          }
        }
      }
      // OJK
      .ojk {
        gap: 2.5rem;
        // background-color: white;

        @include desktop {
          width: 58.563rem;
          display: flex;
          align-items: center;
        }

        .container__image {
          display: flex;
          align-items: center;
          gap: 0.875rem;
        }

        img {
          // width: 5.688rem;
          height: 2.875rem;
          object-fit: cover;
        }

        .disclaimer {
          color: white;
          font-size: 0.875rem;

          @include mobile {
            margin-top: 1.063rem;
            line-height: 1.125rem;
            font-size: 0.625rem;
          }

          @include desktop {
            line-height: 1.75rem;
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    height: 2.813rem;
    background-color: $yellowColor;
    color: #333;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: 0.75rem;
      height: auto;
    }

    .copyright-container {
      width: $widthContainer;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 1rem 1rem;

      @include mobile {
        padding: 1rem 0;
      }

      @include desktop {
        padding: 0;
        display: block;
      }

      .copyright-center {
        text-align: center;

        @include mobile {
          margin-bottom: 0.5rem;
        }
      }
      .copyright-right {
        position: static;
        top: 0;
        right: 0;

        @include desktop {
          position: absolute;
        }

        span {
          cursor: pointer;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }
  }
}
