@import "../../global";

.privacy {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 10rem;

  &__container {
    width: $widthContainer;
    margin: 7.625rem auto;

    p {
      font-size: 1rem;
      line-height: 1.625rem;
      text-align: justify;
      letter-spacing: 0.01em;
      // margin-top: 5rem;
      margin-bottom: 1rem;
    }

    ol {
      // margin-top: 5rem;
      list-style-type: upper-roman;
      list-style-position: outside;
      margin-left: 2rem;

      li {
        // margin-left: 0.2rem;
        display: list-item;
        // font-weight: 700;
        // text-transform: uppercase;
        white-space: inherit;
        line-height: 1.625rem;

        // p {
        //   padding: 1rem 0;
        //   font-weight: 400;
        //   text-transform: none;
        // }
      }
    }
  }
}
