@import "../../global";

.faq__tabs__contents {
  width: $widthContainer;
  margin: 5rem auto;

  &__title {
    font-size: 3.125rem;
    font-weight: 700;
    color: $blackColor;
    letter-spacing: 0.1rem;
    line-height: 4.375rem;
    text-align: center;
    margin-bottom: 3.75rem;
  }

  &__cards {
    transition: all 1s ease-in;
    animation: fadeIn 0.5s;

    .cards {
      width: 100%;
      background-color: $whiteColor;
      // border-radius: 2rem;
      border-radius: 0.75rem;
      margin-bottom: 2rem;
      border: 0.063rem solid rgba(51, 51, 51, 0.2);

      &__title {
        font-size: 1rem;
        font-weight: 600;
        color: $blackColor;
        // margin-bottom: 0.2rem;
        transition: all ease-out 0.3s;
        padding: 1.25rem 2rem;
        // border-radius: 2rem;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: $yellowColor;
          cursor: pointer;
        }
      }

      .active {
        background-color: $yellowColor;
        outline: 0.063rem solid $yellowColor;
      }

      &__desc {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: $grayColor;
        padding: 1.25rem 2rem;
        // border-radius: 2rem;
        border-radius: 0.75rem;
        animation: fadeIn 0.5s;
      }
    }
  }
}
