@import "../../global";

.news__event {
  width: 100%;
  margin-top: $heigtHeader;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: $widthContainer;
    padding: 5.125rem 0;
  }
}
