@import "../../global";

.press__release {
  width: 100%;
  margin-bottom: 6.5rem;

  &__title {
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 1.25rem;
    font-size: 3.125rem;
    line-height: 4.375rem;
    letter-spacing: 0.02em;

    @include mobile {
      font-size: 2.75rem;
      line-height: 3rem;
    }
  }

  &__cards {
    width: 100%;
    // background: aqua;
    overflow: hidden;
    padding: 1.875rem 0;

    @include mobile {
      overflow: auto;
    }

    .card__item {
      width: auto;
      display: flex;
      transition: all 0.5s ease-in-out;
    }
  }
  .card__buttons {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include mobile {
      margin-top: 1.5rem;
    }

    .button__wrapper {
      display: flex;
      gap: 1.5rem;

      @include mobile {
        position: absolute;
      }

      .card__button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        border: 0.2rem solid $grayColor;
        display: flex;
        align-items: center;
        justify-self: center;
        transition: 0.3s ease;

        &:hover,
        &:focus {
          background: $yellowColor;
          cursor: pointer;
          border: 0.2rem solid $yellowColor;
        }

        &:hover .prev__button path {
          fill: white;
        }

        &:hover .next__button path {
          fill: white;
        }

        .prev__button {
          width: 2.5rem;
          height: 2.5rem;
        }

        .next__button {
          width: 2.5rem;
          height: 2.5rem;
          transform: rotate(180deg);
        }
      }
    }
  }
}
