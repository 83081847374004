@import "../../global";

.couponts__member {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 7rem;
  margin-bottom: 7rem;

  &__container {
    width: $widthContainer;

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    .member__image {
      flex: 1.1;
      overflow: hidden;

      img {
        width: 31.25rem;
        height: 22.563rem;

        @include mobile {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .member__content {
      flex: 1;

      &__title {
        font-size: 3.125rem;
        font-weight: 700;
        color: $blackColor;
        letter-spacing: 0.1rem;
        line-height: 4.375rem;

        span {
          display: block;
          color: $yellowColor;
        }
      }

      &__desc {
        font-size: 1rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.7);
        letter-spacing: 0.1rem;
        line-height: 2.125rem;
        margin-top: 2rem;

        p {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
