@import "../../global.scss";

li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.188rem;
  letter-spacing: 0.02rem;
  color: $blackColor;
  padding: 0.625rem 0;
  position: relative;
  white-space: nowrap;

  @include mobile {
    display: block;
  }

  .decoration__none.active {
    border-bottom: none;
  }

  a {
    text-decoration: none;
    color: inherit;
    // height: 2.5rem;
    height: 1.2rem;
    transition: border-bottom-style 0.7s ease;

    &.active,
    &:hover {
      border-bottom-width: 0.125rem;
      border-bottom-style: solid;
      border-bottom-color: $yellowColor;
    }

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include mobile {
        font-weight: bold;
      }

      .arrow {
        @include mobile {
          position: absolute;
          right: 2vw;
        }
      }
    }
  }

  .id {
    animation: moveLeft 1s;
  }

  @keyframes moveLeft {
    from {
      transform: translateX(2rem);
      opacity: 0;
    }
    to {
      transform: translateX(0);
    }
  }

  .en {
    animation: moveRight 1s;
  }

  @keyframes moveRight {
    from {
      transform: translateX(-2rem);
      opacity: 0;
    }
    to {
      transform: translateX(0);
    }
  }

  &.mobile {
    width: 100%;
    gap: 0;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.translate {
  border-radius: 2.5rem;
  color: $grayColor;
  border: 0.131rem solid $grayColor;
  font-weight: 600;
  width: 5.625rem;
  padding: 0.625rem 1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
  }

  &.mobile {
    position: absolute;
    width: 25vw;
    left: 1rem;
    right: 1rem;
    top: 0;
  }
}

.download {
  background-color: $yellowColor;
  border-radius: 2.5rem;
  border: 0.131rem solid $yellowColor;
  padding: 0.625rem 1.5rem;
  font-weight: 600;
  // outline: 0.185rem solid $yellowColor;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: #f39c12;
  }

  a:hover {
    border-bottom: none;
  }

  &.mobile {
    position: absolute;
    width: 91vw;
    left: 0.9rem;
    right: 0.9rem;
    bottom: 0;
    background-color: $yellowColor;
    color: $blackColor;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      color: $whiteColor;
    }
  }
}

// menu
.curve {
  z-index: 2;
  width: 2rem;
  height: 2rem;
  position: absolute;
  // transform: rotate(42deg);
  // left: 30%;
  top: 45%;
  left: 50%;
  transform: translate(-50%) rotate(42deg);
  background-color: rgb(250, 250, 250);
  // box-shadow: 0 0.1rem 0.1rem $grayColor;
  animation: slide 0.5s ease;

  @include desktop {
    top: 4.8rem;
  }

  @include mobile {
    display: none;
  }
}

.items {
  position: relative;
  // padding: 0.625rem;
  z-index: 2;
  overflow: hidden;
  animation: slide 0.5s ease;
  // background-color: tomato;

  @include mobile {
    transform: translateY(1rem);
  }

  @include desktop {
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    width: 19.563rem;
    position: absolute;
    // left: -135%;
    // left: -8.3vw;
    // left: -8.1vw;
    top: 5.7rem;
    left: 50%;
    transform: translate(-50%);
  }

  @keyframes slide {
    from {
      opacity: 0;
      top: -0.5rem;
    }

    to {
      opacity: 1;
    }
  }

  .item {
    font-size: 1rem;
    width: 100%;

    a {
      span {
        font-weight: normal;
      }
    }

    @include mobile {
      font-size: 0.875rem;
      padding-left: 1rem;
      margin: 1rem 0;
      height: 1.8rem;
    }

    @include desktop {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 16.563rem;
      height: 3.75rem;
    }
  }
}
