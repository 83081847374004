@import "../../global.scss";
.ourservices {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;

  @include mobile {
    overflow: hidden; // Membatasi
    margin-top: 5rem;
  }

  .ourservices-container {
    width: $widthContainer;

    // @include desktop {
    //   height: 45.5rem;
    // }

    @include desktop {
      display: flex;
      gap: 2.625rem;
      padding: 0;
    }

    .ourservices-title {
      color: rgba(51, 51, 51, 1);
      font-size: 3.125rem;
      font-weight: 700;
      line-height: 4.375rem;
      text-align: center;

      @include desktop {
        width: 25%;
        text-align: left;
      }

      @include mobile {
        font-size: 2.5rem;
        line-height: 2.125rem;
        padding: 0 1rem;
      }
    }

    .ourservices-cards {
      width: 75%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include mobile {
        width: 100vw;
        margin-top: 1rem;
        flex-wrap: nowrap;
        overflow-x: scroll;
        // Hide Scrollbar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        // scroll-behavior: smooth;
      }

      .card-container {
        width: 100%;
        margin: 1rem auto;
        border-radius: 1.25rem;
        border: 2px solid #f5f5f5;
        padding: 2rem 1.5rem;
        position: relative;
        transition: all 0.3s ease;
        overflow: hidden;
        width: 18.125rem;
        height: 20.25rem;

        @include mobile {
          width: 18.125rem;
          height: 19.25rem;
          flex-shrink: 0;
          margin-right: 1.7rem;
        }

        &:hover {
          box-shadow: 0px 2px 40px rgba(41, 69, 85, 0.1);
          background-color: white;
          border: 2px solid white;
        }

        img {
          margin-bottom: 1rem;
          width: 5rem;
          height: 5rem;
        }

        .title-card {
          font-size: 1.375rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        .desc-card {
          font-size: 1rem;
          font-weight: 400;
          color: $grayColor;
          line-height: 1.875rem;
          margin-bottom: 1rem;
        }

        .learn-more {
          bottom: 2rem;
          font-size: 0.875rem;
          letter-spacing: 0.05rem;
          font-weight: 700;
          text-transform: uppercase;
          color: $darkGrayColor;
          display: flex;
          align-items: center;
          gap: 0.5rem;

          @include desktop {
            position: absolute;
          }

          a {
            color: inherit;
            text-decoration: none;
          }

          &:hover {
            color: rgba(99, 100, 101, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
}
