@import "../../global";

.products {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 10rem;

  &__tabs {
    width: 100%;
  }
}
