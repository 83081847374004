@import "../../global";

.help {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 10rem;

  &__tabs {
    width: $widthContainer;
    margin: 0 auto;
  }

  .container__contact {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
