@import "../../global";

.comingsoon {
  width: 100%;
  height: 100%;
  margin-top: $heigtHeader;
  display: flex;
  justify-content: center;

  .comingsoon__container {
    width: $widthContainer;
    height: 100vh;
    margin: 0 auto;
    // background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .decoration {
      position: absolute;
      // left: 70%;
      // right: -5vw;
      // bottom: 0;
      width: 54.5vw;
      right: -6%;
      z-index: -2;
      bottom: -20%;

      @include mobile {
        bottom: -5%;
        width: 60%;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
    }

    .wrapper__images {
      width: 21.875rem;
      height: 20rem;

      img {
        width: 100%;
      }
    }

    .heading {
      font-weight: 800;
      font-size: 2.75rem;
      line-height: 4.563rem;
      color: $blackColor;

      @include desktop {
        font-size: 3.75rem;
      }
    }

    .sub__heading {
      font-size: 1.3rem;
      line-height: 2rem;
      color: $blackColor;

      @include desktop {
        line-height: 2.2rem;
        font-size: 1.5rem;
      }
    }
  }
}
