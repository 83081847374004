@import "../../global";

.products__tabs__contents {
  width: $widthContainer;
  margin: 5rem auto;

  &__title {
    font-size: 3.125rem;
    font-weight: 700;
    color: $blackColor;
    letter-spacing: 0.1rem;
    line-height: 4.375rem;
    text-align: center;
    margin-bottom: 3.75rem;

    @include mobile {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }

  &__cards {
    transition: all 1s ease-in;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.5rem;
    }
    .cards {
      // width: 25rem;
      width: 31.1%;
      height: 20.375rem;
      background-color: $whiteColor;
      border-radius: 2rem;
      margin-bottom: 2rem;
      // padding: 2.5rem;
      padding: 2.5%;
      transition: all ease-out 0.3s;
      overflow: hidden;
      position: relative;

      @include mobile {
        padding: 1.2rem;
        width: 100%;
      }

      &:hover {
        background-color: $yellowColor;
      }

      &:hover .cards__image {
        background-color: $blackColor;
      }

      // Styling kalau pakai 2 gambar
      &:hover .cards__image img.img__front {
        display: block;
      }

      &:hover .cards__image img.img__back {
        display: none;
      }

      &:hover .cards__desc {
        color: $blackColor;
      }

      // Styling kalau pakai svg
      &:hover .icon__feature path.black {
        // stroke: white;
        fill: white;
      }

      &:hover .icon__feature path.blackStroke {
        stroke: white;
        // fill: white;
      }

      .batik {
        position: absolute;
        top: 0;
        right: -3rem;
      }

      &__image {
        margin: 0 auto 2rem;
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $whiteColor;
        border-radius: 1.25rem;
        box-shadow: 0px 2px 40px rgba(41, 69, 85, 0.1);
        transition: 0.3s ease;

        img {
          width: 3.125rem;
          height: 3.125rem;
        }

        .img__front {
          display: none;
        }
      }

      &__title {
        font-size: 1.375rem;
        font-weight: 700;
        color: $blackColor;
        text-align: center;
        margin-bottom: 0.75rem;
      }

      &__desc {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.875rem;
        color: $grayColor;
      }
    }
  }
}
