@import "../../global";
.vision {
  width: 100%;
  margin-bottom: 10rem;

  &__container {
    width: $widthContainer;
    margin: 0 auto;
    padding: 5.188rem 4.375rem;
    background-color: #fff7e4;
    border-radius: 1.25rem;

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    @include mobile {
      padding: 2.5rem 1.5rem;
    }

    .first__wrapper {
      @include mobile {
        border-bottom: 0.3rem solid $yellowColor;
      }
    }

    .vision__wrapper {
      width: 34.375rem;
      // height: 17.625rem;
      margin-bottom: 2rem;

      @include mobile {
        width: 100%;
        margin-bottom: 3rem;
      }

      .vision__title {
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $grayColor;
        margin-bottom: 2.438rem;

        @include mobile {
          font-size: 1.25rem;
        }
      }

      .vision__desc {
        padding: 0 1.25rem;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.75rem;
        letter-spacing: 0.04em;
        color: $blackColor;

        @include desktop {
          border-left: 0.5rem solid $yellowColor;
        }

        @include mobile {
          padding: 0 0.8rem;
          font-size: 0.875rem;
          line-height: 1.875rem;
          padding-bottom: 2.5rem;
        }
      }
    }
  }
}
