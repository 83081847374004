@import "../../global";

.tabsicons {
  width: 100%;
  margin-top: -3.5rem;
  margin-bottom: 4.25rem;
  position: relative;

  @include desktop {
    margin-top: -7.5rem;
    margin-bottom: 6.25rem;
  }

  &__container {
    width: $widthContainer;
    // background-color: yellow;
    margin: 0 auto;

    .container__sekunder {
      width: 83%;
      margin: 0 auto;
      //   background-color: white;
      display: flex;
      justify-content: center;
      gap: 2rem;

      @include mobile {
        width: 100%;
      }

      .card__tab {
        width: 9.5rem;
        height: 9.5rem;
        background: #ffffff;
        box-shadow: 0px 2px 40px rgba(41, 69, 85, 0.1);
        border-radius: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.25rem;
        transition: all 0.2s ease;
        border: 0.25rem solid #ffffff;

        &:hover {
          border: 0.25rem solid $yellowColor;
          cursor: pointer;
        }

        @include desktop {
          width: 12.5rem;
          height: 12.5rem;
        }

        .wrapper__img {
          width: 3.75rem;
          height: 3.75rem;

          img {
            width: 100%;
          }
        }

        .title__img {
          font-weight: 600;
          font-size: 1.25rem;
          letter-spacing: 0.01em;
          text-align: center;
          color: $blackColor;
        }
      }
      .active {
        border: 0.25rem solid $yellowColor;
      }
    }
  }
}
