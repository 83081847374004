@import "../../global.scss";

.menu-mobile {
  display: flex;
  justify-content: flex-end;

  @include desktop {
    display: none;
  }

  svg.hamburger {
    fill: $yellowColor;
    width: 1.5rem;
    height: 1.5rem;
    animation: blink 0.5s ease;

    @keyframes blink {
      from {
        opacity: 0;
        transform: rotateZ(45deg);
      }

      to {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .modal-menu {
    position: fixed;
    width: 100%;
    height: calc(100vh - 6.497rem);
    top: 6.497rem;
    right: 0;
    background-color: white;
    animation: slide 1s ease;
    padding: 1rem;
    padding-top: 3.5rem; // sediakan ruangan untuk translate yg absolute

    @keyframes slide {
      from {
        right: -100vw;
      }

      to {
        right: 0;
      }
    }

    @keyframes slideOut {
      from {
        right: 0;
      }

      to {
        right: -100vw;
      }
    }
  }
}
