@import "../../global";

.mpc {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 7rem;
  margin-bottom: 7rem;

  @include mobile {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  &__container {
    width: $widthContainer;

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6.5rem;
    }

    .member__image {
      flex: 0.8;
      overflow: hidden;

      img {
        width: 100%;
        // height: 100%;
      }
    }

    .member__content {
      flex: 1;

      &__title {
        font-size: 3.125rem;
        font-weight: 700;
        color: $blackColor;
        letter-spacing: 0.1rem;
        line-height: 4.375rem;

        span {
          display: block;
          color: $yellowColor;
        }
      }

      &__desc {
        font-size: 1rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.7);
        letter-spacing: 0.1rem;
        line-height: 2.125rem;
        margin-top: 2rem;

        p {
          margin-bottom: 3rem;
        }
      }

      // member items
      // .member__items {
      //   display: flex;
      //   align-items: center;
      //   margin-bottom: 3rem;

      //   .member__icon img {
      //     width: 6rem;
      //   }

      //   .member__detail {
      //     .title {
      //       font-family: Inter;
      //       font-weight: 700;
      //       font-size: 2.5rem;
      //       letter-spacing: 0.02em;
      //       color: $blackColor;
      //       margin-bottom: 1.5rem;
      //     }

      //     .description {
      //       font-weight: 500;
      //       font-size: 1rem;
      //       line-height: 1.875rem;
      //       color: $grayColor;
      //     }
      //   }
      // }
    }
  }
}
