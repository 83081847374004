@import "../../global";

.managementcommitte {
  width: 100%;
  margin-top: $heigtHeader;

  &__container {
    width: $widthContainer;
    // background-color: rgb(218, 218, 218);
    margin: 4.375rem auto 7.75rem;

    .contents__wrapper {
      width: 70%;
      margin: 0 auto;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.875rem;
      color: $blackColor;

      @include mobile {
        width: 100%;
      }

      p {
        margin-bottom: 1.6rem;

        span {
          display: block;
        }
      }

      .pdf {
        border: solid 0.25rem $blackColor;
        border-radius: 2.813rem;
        width: 21.25rem;
        height: 5.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        font-weight: 600;
        transition: all 0.2s ease-in-out;

        @include mobile {
          height: 4.625rem;
          width: 100%;
        }

        &:hover {
          background-color: $yellowColor;
          cursor: pointer;
          border: none;
        }

        img {
          width: 2.625rem;
          height: 2.625rem;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
