@import "../../global";

.ourvalues {
  width: 100%;
  margin-bottom: 10rem;
  display: flex;
  justify-content: center;

  &__container {
    width: $widthContainer;

    .title {
      color: $blackColor;
      font-size: 3.125rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.3rem;
      margin-bottom: 3rem;
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;

      .icon {
        margin-bottom: 1rem;
        width: 18.125rem;
        height: 6.25rem;
        border-radius: 1.25rem;
        background-color: $blackColor;
        display: flex;
        align-items: center;
        padding: 1rem;
        gap: 1rem;
        transition: all 0.3s ease;

        @include mobile {
          margin-bottom: 0.5rem;
          width: 100%;
        }

        &:hover {
          background-color: $yellowColor;
        }
        &:hover .title__icon {
          color: $blackColor;
        }

        .wrapper__img {
          width: 4rem;
          height: 4rem;
          border-radius: 100%;
          background-color: $yellowColor;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            // width: 18.125rem;
            // height: 6.25rem;
            // width: 100%;
            // height: 100%;
            position: absolute;

            @include mobile {
            }
          }
        }

        .title__icon {
          font-size: 1.5rem;
          letter-spacing: 0.04em;
          color: white;

          // &:hover {
          //   color: $blackColor;
          // }

          &::first-letter {
            font-weight: bold;
          }
        }
      }
    }
  }
}
