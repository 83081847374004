@import "../../global";

.contactus {
  width: $widthContainer;
  //   background-color: yellowgreen;
  border-radius: 1.25rem;
  overflow: hidden;

  @include desktop {
    display: flex;
  }

  .message {
    padding: 2.5rem;
    flex: 2;
    background-color: $blackColor;
    position: relative;

    @include mobile {
      margin-bottom: 2rem;
      border-radius: 1.25rem;
    }

    .batik {
      position: absolute;
      top: -9rem;
      right: -3rem;
      width: 20rem;
      transform: rotateY(180deg);

      @include mobile {
        width: 12rem;
        top: -4.5rem;
        right: -2rem;
      }

      img {
        width: 100%;
      }
    }

    .title {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.04em;
      color: #ffffff;
      margin-bottom: 2.5rem;
    }

    form {
      .group__control {
        // display: flex;
        justify-content: space-between;
        margin-bottom: 2.5rem;

        @include mobile {
          margin-bottom: 0;
        }

        @include desktop {
          display: flex;
        }

        input,
        textarea {
          // width: 22.625rem;
          width: 49%;
          height: 3.5rem;
          border: none;
          outline: none;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 0.75rem;
          padding: 1rem 1.25rem;
          color: rgba(255, 255, 255, 0.6);
          font-size: 1rem;
          font-weight: 500;

          @include mobile {
            width: 100%;
            margin-bottom: 2.5rem;
          }

          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
            font-size: 1rem;
            font-weight: 500;
          }
        }

        // Alert pada form
        .success {
          border: 3px solid rgba(34, 255, 0, 0.2);
        }

        .error {
          border: 3px solid rgba(255, 0, 0, 0.2);
        }

        textarea {
          width: 100%;
          height: 8rem;
          resize: none;
          font-family: Inter;
        }
      }

      .btn__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .btn__contact {
          width: 15rem;
          height: 4rem;
          background: $yellowColor;
          border-radius: 100px;
          font-size: 0.875rem;
          font-weight: bold;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          transition: all 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: orange;
          }
        }
      }
    }
  }
  .contact {
    padding: 2.5rem;
    background-color: $yellowColor;
    flex: 1;
    position: relative;

    @include mobile {
      border-radius: 1.25rem;
    }

    .batik__contact {
      position: absolute;
      top: 14rem;
      right: -6rem;
      width: 20rem;

      @include mobile {
        width: 12rem;
        top: 13rem;
        right: 0rem;
      }

      img {
        width: 100%;
      }
    }

    .title {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.04em;
      color: $blackColor;
      margin-bottom: 2.5rem;
    }

    .kontak {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $blackColor;
      font-weight: bold;
      font-size: 1.25rem;
      letter-spacing: 0.02em;
      margin-bottom: 2.5rem;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn__wrapper {
      position: absolute;
      bottom: 2rem;

      @include mobile {
        bottom: 0.2rem;
      }

      .btn__contact {
        width: 15rem;
        height: 4rem;
        background: $blackColor;
        color: $yellowColor;
        border-radius: 100px;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        transition: all 0.2s ease;

        &:hover {
          cursor: pointer;
          background-color: rgb(19, 19, 19);
        }
      }
    }
  }
}
