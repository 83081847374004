@import "../../global.scss";

ul {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  // background-color: grey;

  @include desktop {
    display: flex;
  }
}
