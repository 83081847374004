@import "../../global.scss";

.header {
  width: 100%;
  height: $heigtHeader;
  background-color: $whiteColor;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1.875rem rgba(0, 5, 58, 0.05);

  .header-container {
    width: $widthContainer;
    // padding: 0 1rem;
    display: flex;
    align-items: center;
    // background-color: grey;

    @include desktop {
      padding: 0;
    }

    .header-logo {
      flex: 3;
      margin-top: -0.5rem;

      img {
        width: 10rem;
        object-fit: cover;
        // height: 2.352rem;
      }
    }

    .header-nav {
      flex: 9;
    }
  }
}
