@import "../../global.scss";

.privilege {
  width: 100%;
  padding: 0 1rem;
  margin-top: 3rem;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
    padding: 0;
  }

  .privilege-container {
    background: url("../../assets/privilege.png") no-repeat;
    background-size: cover;
    padding: 1rem;
    border-radius: 2rem;

    @include desktop {
      width: $widthContainer;
      height: 44.577rem;
      display: flex;
      flex-direction: column;
      padding: 6.188rem 8.125rem;
      justify-content: space-between;
      align-items: center;
    }

    .privilege-subtitle {
      text-transform: uppercase;
      color: $darkGrayColor;
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
    }

    .privilege-title {
      color: $blackColor;
      font-size: 3.125rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.3rem;

      @include mobile {
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
        margin-top: 0.75rem;
        margin-bottom: 1.25rem;
      }
    }

    .privilege-cards {
      background-color: white;
      border-radius: 1.25rem;
      padding: 3.75rem 1.813rem 2.5rem;

      @include desktop {
        width: 63.75rem;
        height: 21.5rem;
        display: flex;
        justify-content: space-between;
      }

      .privilege-card {
        width: 100%;
        // height: 15.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;

        @include desktop {
          width: 17.625rem;
          height: 15.25rem;

          &:nth-child(2)::after {
            content: "";
            position: absolute;
            width: 21rem;
            height: 14rem;
            border-left: 1px dashed rgba(51, 51, 51, 0.2);
            border-right: 1px dashed rgba(51, 51, 51, 0.2);
          }
        }

        img {
          width: 6.25rem;
          height: 6.25rem;
        }

        h4 {
          font-size: 1.375rem;
          font-weight: 700;
          color: $blackColor;
          //   text-align: center;

          @include mobile {
            margin-top: 1.25rem;
            margin-bottom: 0.75rem;
          }
        }

        p {
          font-size: 1rem;
          color: $grayColor;
          line-height: 1.875rem;
          text-align: center;
        }
      }
    }
  }
}
