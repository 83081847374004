@import "../../global";

.products__registers {
  width: 100%;
  margin-top: 10rem;
  position: relative; // absolute to arrow

  // background-color: rgb(248, 248, 196);

  &__title {
    width: $widthContainer;
    margin: 0 auto;
    font-size: 3.125rem;
    font-weight: 700;
    color: $blackColor;
    letter-spacing: 0.1rem;
    line-height: 4.375rem;
    text-align: center;
    margin-bottom: 2.5rem;

    @include mobile {
      font-size: 1.5rem;
      line-height: 2.125rem;
      padding: 0 1rem;
    }
  }

  // Khusus tab produk no. 1
  .center {
    @include mobile {
      justify-content: center;
    }
  }

  // tabs
  &__container {
    width: 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    overflow-x: auto;

    @include mobile {
      justify-content: flex-start;
      padding: 0 1rem;
    }

    &__tab {
      padding: 0.75rem 1.5rem;
      border-radius: 6.25rem;
      text-transform: uppercase;
      border: 1px solid #838484;
      background: #ffffff;
      color: #838484;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      transition: all 0.3s ease-out;
      width: auto;
      white-space: nowrap;

      &:hover,
      &.active {
        background: $yellowColor;
        cursor: pointer;
        color: rgba(51, 51, 51, 1);
        border: 1px solid $yellowColor;
      }
    }
  }

  // Cards Register
  &__cards {
    margin: 0 auto;
    margin-top: 3.875rem;
    // width: $widthContainer;
    width: 100%;
    // height: 36.875rem;
    // height: 36.875rem;
    // overflow: hidden;
    // padding: 2rem 0;

    .wripper__slider {
      overflow-x: auto;
      // Hide Scrollbar
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      z-index: 2;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      border: 0.2rem solid $grayColor;
      display: flex;
      align-items: center;
      justify-self: center;
      transition: 0.3s ease;

      &:hover,
      &:focus {
        background: $yellowColor;
        cursor: pointer;
        border: 0.2rem solid $yellowColor;
      }

      &:hover .prev__button path {
        fill: white;
      }

      &:hover .next__button path {
        fill: white;
      }

      .prev__button {
        width: 2.5rem;
        height: 2.5rem;
      }

      .next__button {
        width: 2.5rem;
        height: 2.5rem;
        transform: rotate(180deg);
      }
    }

    .cards__slider {
      height: 100%;
      // transition: all 0.3s ease-in-out;
      // animation: slider 10s ease-in-out infinite;
      padding: 0 5%;
      display: flex;
      gap: 4rem;

      @keyframes slider {
        0% {
          transform: translateX(0%);
        }
        20% {
          transform: translateX(0%);
        }
        40% {
          transform: translateX(-10%);
        }
        60% {
          transform: translateX(-25%);
        }
        80% {
          transform: translateX(-55%);
        }
        100% {
          transform: translateX(0%);
        }
      }

      @include mobile {
        gap: 1rem;
        padding: 0 12%;
      }

      .card__wrapper {
        width: 18.125rem;
        // height: 36.875rem;
        margin-bottom: 1rem;
        // padding: 2rem 1rem;
        padding: 1rem 0.5rem;

        .image__container {
          width: 15.313rem;
          height: 29.563rem;
          // width: 18.125rem;
          // height: 32.5rem;
          position: relative;
          // background-color: blue;

          img {
            // width: 13.188rem;
            // height: 28.861rem;
            width: 100%;
            // height: 100%;
            object-fit: cover;
          }

          // .card__number {
          //   position: absolute;
          //   bottom: 2.1rem;
          //   right: 0.688rem;
          //   width: 4.25rem;
          //   height: 4.25rem;
          //   color: rgba(0, 0, 0, 1);
          //   border-radius: 50%;
          //   background-color: $yellowColor;
          //   font-size: 2.5rem;
          //   font-weight: 700;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          // }
        }

        .card__caption {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.875rem;
          color: #838484;
          text-align: center;
          margin-top: -2.7rem;
        }
      }
    }
  }
}
