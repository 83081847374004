@import "../../global";

.iconsbillpayment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   margin-top: 8.75rem;
  margin-top: 7rem;

  &__container {
    width: $widthContainer;

    .billpayment__title {
      font-size: 3.125rem;
      font-weight: 700;
      color: $blackColor;
      letter-spacing: 0.1rem;
      line-height: 4.375rem;
      text-align: center;
      margin-bottom: 3.75rem;
      width: 60%;
      margin: 0 auto;
      margin-bottom: 3.75rem;

      @include mobile {
        font-size: 1.8rem;
        line-height: 2.8rem;
        width: 100%;
      }
    }

    .icons__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2.5rem;

      .image__wrapper {
        width: 11.25rem;
        height: 10.125rem;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
        margin-bottom: 5.25rem;
        // background-color: tomato;
        // padding: 1rem;

        img {
          transition: all 0.3s ease-in-out;
          margin: 1rem auto;

          &:hover {
            transform: scale(1.1);
          }
        }

        &__title {
          color: $blackColor;
          font-size: 1.25rem;
          font-weight: 600;
          text-align: center;
          line-height: 1.5rem;
        }
      }
    }
  }
}
