@import "../../global.scss";

.slideshow-container {
  width: 100%;
  overflow: hidden;
  // background-color: $grayColor;

  @include desktop {
    height: 85%;
  }

  .fade {
    position: relative;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    animation-duration: 1s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // cursor: pointer;

      // @include desktop {
      //   width: 100%;
      //   height: 37.394rem;
      // }

      // @include mobile {
      //   height: 42.563rem;
      // }
    }

    .slides-container {
      position: absolute;
      // top: 7.438rem;
      // left: 11.938rem;
      // max-width: 34.313rem;
      // height: 22.688rem;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      top: 70%;
      left: 13.5%;

      @include mobile {
        width: 77%;
        height: 4rem;
        // top: 1rem;
        // left: 2rem;
        left: 12%;
        top: 90%;
      }

      h2 {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 5.625rem;
        color: $whiteColor;

        @include mobile {
          font-size: 2rem;
          line-height: 2.2rem;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.875rem;
        color: rgba(255, 255, 255, 0.7);

        @include mobile {
          font-size: 0.7rem;
          line-height: 1rem;
        }
      }

      .wrapper-images {
        display: flex;
        align-items: center;
        gap: 2.25rem;

        @include mobile {
          gap: 0;
          justify-content: space-between;
        }

        img {
          // width: 13.063rem;
          // height: 3.875rem;
          width: 11.5rem;
          cursor: pointer;

          @include mobile {
            // width: 7.063rem;
            // height: 1.875rem;
            width: 9rem;
          }
        }
      }
    }

    @keyframes fade {
      0% {
        transform: translate(50%);
        opacity: 0;
      }

      50% {
        transform: scale(1.05);
        opacity: 0.5;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .myslides {
    display: none;
  }

  .dots {
    /* text-align: center; */
    // position: absolute;
    // bottom: 0;
    // left: 42%;
    margin: 0.3rem auto;
    text-align: center;
    animation: blur 1.5s;

    @keyframes blur {
      0% {
        opacity: 0;
        transform: translateY(10vh);
      }

      100% {
        opacity: 0.5;
        transform: translateY(0rem);
      }
    }

    @include desktop {
      //   position: absolute;
      //   bottom: 0;
      //   left: 42%;
      margin: 1.375rem auto;
    }

    .dot {
      height: 0.5rem;
      width: 1.25rem;
      margin: 0 0.35rem;
      background-color: #e4e4e4;
      border-radius: 0.5rem;
      display: inline-block;
      transition: all 0.6s ease;

      @include desktop {
        height: 0.7rem;
        width: 1.7rem;
      }
    }

    .active,
    .dot:hover {
      cursor: pointer;
      background-color: $yellowColor;
    }
  }
}
