@import "../../global";

.termcondition {
  width: 100%;
  margin-top: $heigtHeader;
  margin-bottom: 10rem;

  &__tabs {
    width: 100%;

    .term__container {
      width: $widthContainer;
      margin: 7.625rem auto;

      .term__desc {
        p {
          font-size: 1rem;
          line-height: 1.625rem;
          text-align: justify;
          letter-spacing: 0.01em;
          // margin-top: 5rem;
          margin-bottom: 1rem;
        }

        table {
          width: 100%;
          overflow: hidden;
          border-radius: 1.25rem;
          box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.1);
          margin-bottom: 1rem;

          thead {
            background-color: $blackColor;
            height: 4.5rem;
            color: $whiteColor;
            font-size: 1.375rem;
            font-weight: 700;

            @include mobile {
              font-size: 0.875rem;
            }
          }

          tbody tr {
            height: 4.5rem;
            color: $blackColor;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.01rem;
            transition: all 0.3s linear;

            @include mobile {
              font-size: 0.7rem;
            }

            td {
              // border-right: 0.188rem solid $whiteColor;
              padding-left: 2.5rem;

              &:nth-child(3) {
                background-color: $ligthGrayColor;
                text-align: center;
              }
            }
          }

          tbody tr:nth-child(2) {
            background-color: $ligthGrayColor;
          }
        }

        ul {
          display: list-item;
          list-style-type: disc;
          list-style-position: outside;
          margin-left: 3.5rem;

          li {
            white-space: pre-wrap;
            line-height: 1.625rem;
          }
        }

        ol {
          // margin-top: 5rem;
          list-style-type: decimal;
          list-style-position: outside;
          margin-left: 2rem;

          li {
            // margin-left: 0.2rem;
            display: list-item;
            // font-weight: 700;
            // text-transform: uppercase;
            white-space: inherit;
            line-height: 1.625rem;

            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }
}
