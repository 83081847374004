@import "../../global";

.downloadnow {
  width: 100%;
  height: 100%;
  margin-top: $heigtHeader;
  display: flex;
  justify-content: center;

  .downloadnow__container {
    width: $widthContainer;
    // height: 100vh;
    margin: 0 auto;
    // background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include mobile {
      width: 100%;
    }

    .decoration {
      position: absolute;
      // left: 70%;
      // right: -5vw;
      // bottom: 0;
      width: 54.5vw;
      right: -6%;
      z-index: -2;
      bottom: -20%;

      @include mobile {
        bottom: -5%;
        width: 60%;
        right: 0;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
      padding: 9.375rem;

      @include mobile {
        padding: 2rem 0;
      }

      .wrapper__image {
        width: 20.813rem;
        height: 4.438rem;

        img {
          width: 100%;
        }
      }

      .sub__heading {
        font-size: 1rem;
        line-height: 2rem;
        color: $blackColor;
        width: 75%;

        @include desktop {
          line-height: 2.2rem;
          font-size: 1.3rem;
          width: 34rem;
        }
      }

      .group__images {
        display: flex;
        gap: 1rem;

        @include mobile {
          gap: 0.75rem;
          order: 5;
        }

        .wrapper__image__group {
          width: 12.227rem;
          // height: 3.75rem;

          img {
            width: 100%;
          }
        }
      }

      .wrapper__image__qr {
        width: 12.5rem;
        height: 12.5rem;

        img {
          width: 100%;
        }
      }
    }
  }
}
