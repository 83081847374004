@import "../../global";

// tabs
.products__tabs__container {
  width: 100%;
  height: 5.5rem;
  background-color: $whiteColor;
  box-shadow: 0px 2px 40px rgba(41, 69, 85, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  overflow-x: auto;
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: $heigtHeader;

  @include mobile {
    justify-content: flex-start;
    padding: 0 1rem;
  }

  &__tab {
    padding: 0.75rem 1.5rem;
    border-radius: 6.25rem;
    text-transform: uppercase;
    border: 1px solid $grayColor;
    background: $whiteColor;
    color: $grayColor;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    transition: all 0.3s ease-out;
    width: auto;
    white-space: nowrap;

    &:hover,
    &.active {
      background: $yellowColor;
      cursor: pointer;
      color: $blackColor;
      border: 1px solid $yellowColor;
    }
  }
}
