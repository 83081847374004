@import "../../global";

.profiles__story {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  margin-bottom: 10rem;

  &__container {
    width: $widthContainer;

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    .story__image {
      flex: 1;
      overflow: hidden;
      margin-right: 1rem;

      img {
        width: 40.688rem;
        height: 34.438rem;

        @include mobile {
          width: 100%;
          height: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .story__content {
      flex: 1.2;

      &__title {
        font-size: 3.125rem;
        font-weight: 700;
        color: $blackColor;
        letter-spacing: 0.1rem;
        line-height: 4.375rem;

        @include mobile {
          font-size: 1.5rem;
          line-height: 2.125rem;
        }
      }

      &__desc {
        font-size: 1rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 0.7);
        letter-spacing: 0.1rem;
        line-height: 2.125rem;
        margin-top: 2rem;

        p {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
